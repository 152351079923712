import {
    BlockStack,
    Layout,
    Page,
    Thumbnail,
    Text,
    LegacyStack,
} from '@shopify/polaris';
import Logo from '../assets/logo.svg';
import { PrivacyPolicy } from '../components';

export function HomePage() {
    return (
        <Page>
            <Layout>
                <Layout.Section>
                    <LegacyStack alignment="center">
                        <LegacyStack.Item>
                            <Thumbnail
                                source={Logo}
                                alt="Rockit Sales & Discounts"
                                size="small"
                            />
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            <Text variant="headingLg" as="h5">
                                Rockit Sales & Discounts - Privacy Policy
                            </Text>
                        </LegacyStack.Item>
                    </LegacyStack>
                </Layout.Section>
                <Layout.Section>
                    <BlockStack gap="400">
                        <PrivacyPolicy />
                    </BlockStack>
                </Layout.Section>
            </Layout>
        </Page>
    );
}
