import { BlockStack, DescriptionList } from '@shopify/polaris';

export function PrivacyPolicy() {
    return (
        <DescriptionList
            items={[
                {
                    term: '',
                    description: <GeneralDescription />,
                },
                {
                    term: 'How Do We Use Your Personal Information?',
                    description:
                        'We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services. ',
                },
                {
                    term: 'Sharing Your Personal Information.',
                    description: <SharingDescription />,
                },
            ]}
        />
    );
}

function GeneralDescription() {
    return (
        <BlockStack gap="400">
            <p>
                Rockit Sales & Discounts “the App” provides the possibility to schedule
                sales and discounts by changing product prices, “the Service” to
                merchants who use Shopify to power their stores. This Privacy Policy
                describes how personal information is collected, used, and shared when
                you install or use the App in connection with your Shopify-supported
                store.
            </p>
            <p>Personal Information the App Collects are the shop name and email.</p>
            <p>
                When you install the App, we are automatically able to access certain
                types of information from your Shopify account: read products.
            </p>
            <p>
                We are using the following technologies: “Cookies” are data files that
                are placed on your device or computer and often include an anonymous
                unique identifier. For more information about cookies, and how to
                disable cookies, visit http://www.allaboutcookies.org. “Log files” track
                actions occurring on the Site, and collect data including your IP
                address, browser type, Internet service provider, referring/exit pages,
                and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic
                files used to record information about how you browse the Site. We also
                use localStorage to track timer sessions.
            </p>
        </BlockStack>
    );
}

function SharingDescription() {
    return (
        <BlockStack gap="400">
            <p>We will not share your personal information.</p>
            <p>
                Finally, we may also share your Personal Information to comply with
                applicable laws and regulations, to respond to a subpoena, search
                warrant or other lawful request for information we receive, or to
                otherwise protect our rights.
            </p>
            <p>
                Your Rights If you are a European resident, you have the right to access
                personal information we hold about you and to ask that your personal
                information be corrected, updated, or deleted. If you would like to
                exercise this right, please contact us through the contact information
                below.
            </p>
            <p>
                Additionally, if you are a European resident we note that we are
                processing your information in order to fulfill contracts we might have
                with you (for example if you make an order through the Site), or
                otherwise to pursue our legitimate business interests listed above.
                Additionally, please note that your information will be transferred
                outside of Europe, including to Canada and the United States.
            </p>
            <p>
                Changes We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other operational,
                legal or regulatory reasons.
            </p>
            <p>
                Contact Us For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please contact us
                by email at support@digitalrockets.app.
            </p>
        </BlockStack>
    );
}
