import '@shopify/polaris/build/esm/styles.css';

import { PolarisProvider } from './components';
import { HomePage } from './pages';

import { Frame } from '@shopify/polaris';

export default function App() {
    return (
        <PolarisProvider>
            <Frame>
                <HomePage />
            </Frame>
        </PolarisProvider>
    );
}
